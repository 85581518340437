<template>
    <div
        v-if="value && isValue"
        class="block max-w-full truncate"
        :title="value"
    >
        {{value}}
    
    </div>
    </template>
    
    <script>
    
    export default {
        name: 'rowTitle',
        props: {
            value: {
                type: [String, Number],
                required: true,
            },
            color: {
                type: String,
                default: () => '#939495'
            },
            isValue: {
                type: Boolean,
                default: true,
            }
        },
    };
    </script>
    
    <style lang="scss" scoped>
    </style>
    